<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">项目部</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >组织机构</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >组织机构</el-breadcrumb-item
        >
        <el-breadcrumb-item>项目部</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="input-box">
          <div class="title-box">项目代码:</div>
          <el-input v-model="code" placeholder="请输入项目代码"></el-input>
        </div>
        <div class="input-box">
          <div class="title-box">项目名称:</div>
          <el-input v-model="name" placeholder="请输入项目名称"></el-input>
        </div>
        <div class="input-box">
          <div class="title-box">状态:</div>
          <el-select
            class="card-select"
            v-model="state"
            placeholder="请选择状态"
            filterable
            clearable
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <button class="btn-pre" @click="getProjects(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-add" @click="newBuilt" v-if="powers.add">
          <i class="fa ion-plus-round"></i>新建
        </button>
        <button class="btn-pre" @click="batch">
          <i class="fa fa-edit"></i>批量设置上级
        </button>
      </div>
      <div class="table-box">
        <el-table
          ref="doLayout"
          class="doLayout"
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          @selection-change="handleSelectionChange"
          row-key="ProjectID"
          lazy
          :load="load"
          :tree-props="{ children: 'children', hasChildren: 'HasChildren' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column type="selection" width="45" label="#">
          </el-table-column>
          <el-table-column prop="ProjectID" label="项目id" width="80" />
          <el-table-column prop="ProjectCode" label="项目代码" width="80" />

          <el-table-column prop="ProjectName" label="中文名称" width="80" />
          <el-table-column prop="ProjectNameEN" label="英文名称" width="80" />
          <el-table-column prop="StartTime" label="开始日期" width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.StartTime">
                {{ scope.row.StartTime | timeFormater }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="EndTime" label="结束日期" width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.EndTime">
                {{ scope.row.EndTime | timeFormater }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Para1" label="是否展示" width="80">
            <template slot-scope="scope">
              <div class="label-success approval" v-if="scope.row.Para1 === 1">
                是
              </div>
              <div class="label-warning approval" v-if="scope.row.Para1 === 2">
                否
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="ProjectState" label="状态" width="80">
            <template slot-scope="scope">
              <div
                class="label-primary approval"
                v-if="scope.row.ProjectState === 0"
              >
                进行中
              </div>
              <div
                class="label-warning approval"
                v-if="scope.row.ProjectState === 1"
              >
                已暂停
              </div>
              <div
                class="label-info approval"
                v-if="scope.row.ProjectState === 2"
              >
                已结束
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="ProjectNumber"
            label="项目
           编号"
            width="80"
          />
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <i
                class="fa fa-edit"
                v-if="powers.update"
                @click="edit(scope.row)"
              ></i>
              <i class="fa fa-search" @click="view(scope.row)"></i>
              <i
                class="fa fa-trash"
                v-if="powers.delete"
                @click="deleteProject(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getProjects(1)"
          @current-change="getProjects"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="
        dialogType === 1
          ? '新建项目部'
          : dialogType === 2
          ? '编辑项目部'
          : '查看项目部'
      "
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="from-box">
            <div class="name">项目代码:</div>
            <el-input
              v-model="parameter.Code"
              :disabled="dialogType === 3"
              placeholder="项目代码"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">项目名称(中文)：</div>
            <el-input
              v-model="parameter.NameCHS"
              :disabled="dialogType === 3"
              placeholder="项目名称(中文)"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">项目名称(英文):</div>
            <el-input
              v-model="parameter.NameEN"
              :disabled="dialogType === 3"
              placeholder="项目名称(英文)"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">状态:</div>
            <el-select
              v-model="parameter.State"
              :disabled="dialogType === 3"
              placeholder="状态"
              filterable
              clearable
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">开始日期:</div>
            <el-date-picker
              :picker-options="pickerOptionsStart"
              v-model="parameter.StartDate"
              :disabled="dialogType === 3"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>

          <div class="from-box">
            <div class="name">结束日期:</div>
            <el-date-picker
              :picker-options="pickerOptionsEnd"
              v-model="parameter.EndDate"
              :disabled="dialogType === 3"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">选择展示:</div>
            <el-select
              v-model="parameter.DisplayInSearchList"
              :disabled="dialogType === 3"
              placeholder="选择展示"
              filterable
              clearable
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="from-box">
            <div class="name">项目编号</div>
            <el-input
              v-model="parameter.Number"
              placeholder="项目编号"
              :disabled="dialogType === 3"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">缩写:</div>
            <el-input
              v-model="parameter.Abbreviation"
              :disabled="dialogType === 3"
              placeholder="缩写"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">分类主体名称:</div>
            <el-input
              v-model="parameter.SubjectTypeName"
              :disabled="dialogType === 3"
              placeholder="分类主体名称"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="single">
            <div class="name">上级项目部:</div>
            <el-select
              v-model="parameter.ParentIds"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in allProjectList"
                :key="item.ProjectID"
                :disabled="myProjectId === item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box">
          <div class="single">
            <div class="name">项目描述:</div>
            <el-input
              type="textarea"
              :rows="2"
              :disabled="dialogType === 3"
              placeholder="项目描述"
              v-model="parameter.Description"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="handleClose" class="pop-close">取消</el-button
        ><el-button class="pop-save" @click="addUpRoles" v-if="dialogType !== 3"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="批量设置上级"
      :visible.sync="dialogVisible2"
      width="800px"
      :before-close="handleClose2"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="single">
            <div class="name">上级项目部:</div>
            <el-cascader
              v-model="ParentIds"
              :options="tree"
              :props="props"
              clearable
              filterable
            ></el-cascader>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="handleClose2" class="pop-close">取消</el-button
        ><el-button class="pop-save" @click="addUpRoles2">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { auditRecords } from "@/utils/tableData";
import { reproduce } from "@/utils/recursion";
import {
  getProjects,
  getProjectsChildren,
  getProjectsTree,
  addProject,
  getProjectsId,
  updateProject,
  deleteProject,
  getProjectAll,
  batchUpdateProject,
} from "@/api/user";

export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      auditRecords: auditRecords,
      dialogVisible: false,
      dialogVisible2: false,
      dialogType: 1, //1新建2、编辑3、查看
      options1: [
        {
          value: 0,
          label: "正在进行",
        },
        {
          value: 1,
          label: "项目暂停",
        },
        {
          value: 2,
          label: "项目结束",
        },
      ],
      options2: [
        {
          value: 1,
          label: "展示",
        },
        {
          value: 2,
          label: "不展示",
        },
      ],
      senior: false,
      value1: null,
      value2: null,
      value3: null,
      page: 1,
      size: 10,
      total: null,
      code: null,
      name: null,
      state: null,
      number: null,
      Id: 0,
      ParentId: 0,
      DepartmentId: 0,
      parameter: {
        Id: 0,
        Code: "",
        NameCHS: "",
        NameEN: "",
        StartDate: "",
        EndDate: "",
        Description: "",
        State: null,
        Number: "",
        Abbreviation: "",
        SubjectTypeName: "",
        ParentIds: null,
        DisplayInSearchList: null,
      },
      tree: [],
      allProjectList: [],
      props: {
        value: "ProjectID",
        label: "ProjectName",
        children: "Children",
        checkStrictly: true,
      },
      treeObj: {},
      pickerOptionsStart: {
        disabledDate: (time) => {
          let endDateVal = this.parameter.EndDate;
          if (endDateVal) {
            return (
              time.getTime() >
              new Date(endDateVal).getTime() - 1 * 24 * 60 * 60 * 1000
            );
          }
          return time.getTime() < Date.parse("1899-12-31");
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          let beginDateVal = this.parameter.StartDate;
          if (beginDateVal) {
            return time.getTime() < new Date(beginDateVal).getTime();
          }
          return time.getTime() < Date.parse("1899-12-31");
        },
      },
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      myProjectId: null,
      loading: true,
      ParentIds: [],
    };
  },
  filters: {
    timeFormater(value) {
      value = value.substring(0, 10);
      return value;
    },
  },
  computed: {},
  methods: {
    deleteItem(index) {
      this.messageList.splice(index, 1);
    },
    handleNoticeClose() {
      this.noticeDrawer = false;
    },

    handleClose() {
      this.dialogVisible = false;
      this.myProjectId = null;
      this.parameter = {
        Id: 0,
        Code: "",
        NameCHS: "",
        NameEN: "",
        StartDate: "",
        EndDate: "",
        Description: "",
        State: null,
        Number: "",
        Abbreviation: "",
        SubjectTypeName: "",
        ParentIds: null,
        DisplayInSearchList: null,
      };
    },
    handleClose2() {
      this.dialogVisible2 = false;
      this.ParentIds = [];
      this.getTree();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log("1111", this.multipleSelection);
    },
    newBuilt() {
      this.dialogVisible = true;
      this.dialogType = 1;
      this.roleName = "";
      this.enable = false;
    },
    edit(row) {
      this.dialogVisible = true;
      this.dialogType = 2;

      const multipleSelectionItem = row;
      this.myProjectId = multipleSelectionItem.ProjectID;
      getProjectsId({ id: multipleSelectionItem.ProjectID }).then((res) => {
        const response = res.response;
        if (res.status === 200) {
          this.parameter = {
            Id: response.ProjectID,
            Code: response.ProjectCode,
            NameCHS: response.ProjectName,
            NameEN: response.ProjectNameEN,
            StartDate: response.StartTime.substring(0, 10),
            EndDate: response.EndTime.substring(0, 10),
            Description: response.ProjectDescription,
            State: response.ProjectState,
            Number: response.ProjectNumber,
            Abbreviation: response.Abbreviation,
            SubjectTypeName: response.SubjectTypeName,
            ParentIds: [],
            DisplayInSearchList: response.Para1,
          };
          if (response.ParentId) {
            this.parameter.ParentIds = response.ParentId;
          }
        }
      });
    },
    view(row) {
      this.dialogVisible = true;
      this.dialogType = 3;
      const multipleSelectionItem = row;

      getProjectsId({ id: multipleSelectionItem.ProjectID }).then((res) => {
        const response = res.response;
        if (res.status === 200) {
          this.parameter = {
            Id: response.ProjectID,
            Code: response.ProjectCode,
            NameCHS: response.ProjectName,
            NameEN: response.ProjectNameEN,
            StartDate: response.StartTime,
            EndDate: response.EndTime,
            Description: response.ProjectDescription,
            State: response.ProjectState,
            Number: response.ProjectNumber,
            Abbreviation: response.Abbreviation,
            SubjectTypeName: response.SubjectTypeName,
            ParentId: response.ParentId,
            DisplayInSearchList: response.Para1,
          };
        }
      });
    },
    doLayout() {
      this.$nextTick(() => {
        this.$refs.doLayout.doLayout();
      });
    },
    //获取项目部列表
    getProjects(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        isRoot: true,
        code: this.code,
        name: this.name,
        state: this.state,
        number: this.number,
      };
      getProjects(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
          // this.doLayout();
        }
        this.loading = false;
      });
    },
    resetLazyTree() {
      this.$set(this.$refs.doLayout.store.states, "lazyTreeNodeMap", {});
    },
    //获取子选项
    load(tree, treeNode, resolve) {
      this.treeObj[tree.ProjectID] = { tree, treeNode, resolve };
      getProjectsChildren({ id: tree.ProjectID }).then((res) => {
        if (res.status === 200) {
          if (res.response.length === 0) {
            this.resetLazyTree();
          }
          resolve(res.response);
        }
      });
    },
    //添加编辑角色
    addUpRoles(type) {
      if (this.parameter.ParentIds && this.parameter.ParentIds.length !== 0) {
        const item = JSON.parse(JSON.stringify(this.parameter.ParentIds));
        this.parameter.ParentIds = [];
        this.parameter.ParentIds.push(item);
      }
      if (this.dialogType === 1) {
        //新增
        addProject(this.parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("创建成功");
            this.getProjects();
            this.handleClose();
            this.getTree();
            for (let i in this.treeObj) {
              const { tree, treeNode, resolve } = this.treeObj[i];

              this.load(tree, treeNode, resolve);
            }
          }
        });
      } else {
        updateProject(this.parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.getProjects();
            this.handleClose();
            this.getTree();
            for (let i in this.treeObj) {
              const { tree, treeNode, resolve } = this.treeObj[i];

              this.load(tree, treeNode, resolve);
            }
          }
        });
      }
    },
    //删除项目部
    deleteProject(row) {
      let data = { id: "" };
      data.id = row.ProjectID;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteProject(data).then((res) => {
          //
          if (res.status === 200) {
            for (let i in this.treeObj) {
              const { tree, treeNode, resolve } = this.treeObj[i];
              this.load(tree, treeNode, resolve);
            }
            this.$nextTick(() => {
              this.$message.success("删除成功");
            });
          }
        });
      });
    },

    getTree() {
      getProjectsTree().then((res) => {
        if (res.status === 200) {
          this.tree = reproduce(res.response, [], false);
        }
      });
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取全部项目部
    getProjectAll() {
      getProjectAll().then((res) => {
        if (res.status === 200) {
          this.allProjectList = [];
          res.response.forEach((item) => {
            if (!item.ParentId) {
              this.allProjectList.push(item);
            }
          });
        }
      });
    },

    //批量
    batch() {
      console.log("1111", this.multipleSelection);
      if (this.multipleSelection.length === 0) {
        this.$message.warning("至少选中一个项目");
        return;
      }
      this.dialogVisible2 = true;
      this.tree = reproduce(this.tree, this.multipleSelection, false);
    },
    addUpRoles2() {
      const data = {
        ParentIds: this.ParentIds,
        Ids: [],
      };
      this.multipleSelection.forEach((item) => {
        data.Ids.push(item.ProjectID);
      });
      batchUpdateProject(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("设置成功");
          this.handleClose2();
          this.tableData = [];
          this.getProjects();
          this.$forceUpdate();
          for (let i in this.treeObj) {
            const { tree, treeNode, resolve } = this.treeObj[i];
            this.load(tree, treeNode, resolve);
          }
        }
      });
    },
  },

  created() {
    this.getProjects();
    this.getTree();
    this.getButtonPower();
    this.getProjectAll();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 12px;
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-left: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
      .input-box {
        display: flex;
        align-items: center;
        height: 34px;
        .title-box {
          padding: 0 12px;
          border: 1px solid #d2d6de;
          line-height: 32px;
          border-right: none;
          color: #555;
          font-size: 14px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 65px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
    }
  }
  .dialog-main {
    border-bottom: 2px solid #e6e6e6;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 340px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
          margin-right: 10px;
        }
        .el-input {
          width: 250px;
        }
        .el-select {
          width: 250px;
        }
      }
      .single {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
        .name {
          flex-shrink: 0;
          width: 80px;
          margin-right: 10px;
        }
        .el-cascader {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
      }
    }
  }
  .dialog-footer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.btn-add {
  margin-right: 24px;
}
</style>
<style scoped lang='scss'>
// el-table表格对齐
.el-table ::v-deep .el-table__row:not([class*="el-table__row--level-"]) {
  td:nth-child(2) {
    padding-left: 24px !important; //一级数据无Child缩进
  }
}
.el-table ::v-deep .el-table__placeholder {
  margin-left: 3px; //子节点无Child缩进
}
</style>

<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 2px solid #e6e6e6;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> .el-table__placeholder {
  width: 23px;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
